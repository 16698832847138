<template>
    <div
        @click.stop.prevent="$emit('close')"
        class="flex relative mt-8 items-center justify-center top-0 left-0 w-11/12 container mx-auto"
    >
        <div
            @click.stop.prevent
            :id="notpopup ? '' : 'call-window'"
            :class="{ 'h-full p-16': !notpopup, 'ml-4 mr-4 p-10': notpopup }"
            class="bg-white relative rounded-3xl w-full mx-auto shadow-lg"
        >
            <div class="grid grid-cols-12 h-full pb-24">
                <div class="col-span-12 lg:col-span-5 flex">
                    <div>
                        <div class="text-header font-bold w-full mb-2">
                            Pristatymo informacija
                            <div
                                v-if="!user"
                                @click="openLoginScreen"
                                class="text-extrasm text-analog cursor-pointer mt-4"
                            >
                                Prisijungti prie esamos paskyros
                            </div>
                        </div>

                        <ValidationObserver ref="form">
                            <userContactDetails
                                :userInfo="userInfo"
                                :shippingMethodcode="shippingMethod.code"
                                :disableEmail="user"
                            />

                            <inputCheckBox
                                v-if="!user"
                                @on-click="createAccount = !createAccount"
                                :checked="createAccount"
                                text="Sukurti paskyrą"
                            />

                            <createPassword
                                v-if="createAccount"
                                :passwordField="passwordField"
                                :passwordConfirmation="passwordConfirmation"
                            />

                            <inputCheckBox
                                @on-click="privacyConfirm = !privacyConfirm"
                                :checked="privacyConfirm"
                                :method="'privacy'"
                                text='Patvirtinu, kad susipažinau ir sutinku su <span class="font-bold cursor-pointer">grąžinimo tvarka </span> ir <span class="font-bold cursor-pointer"> privatumo politika </span>'
                            />
                            <inputCheckBox
                                @on-click="confirmPromo = !confirmPromo"
                                :checked="confirmPromo"
                                text="Noriu gauti pasiūlymus el. paštu"
                            />
                        </ValidationObserver>
                    </div>
                </div>
                <div :class="`shadow-${showScrollShadow}`" class="col-span-12 lg:col-span-7 pb-4 lg:pb-20 relative">
                    <shippingInfo
                        @select-shipping="selectShippingMethod"
                        @price-updated="refreshPrice"
                        :zityPrice="zityPrice"
                        :userInfo="userInfo"
                        class="mb-8"
                    />
                    <div
                        class="text-black grid grid-cols-12 font-bold relative transition rounded p-4 mb-0 bold text-extrasm cursor-pointer"
                    >
                        <div class="col-span-6">Pavadinimas</div>
                        <div class="col-span-2">Vnt</div>
                        <div class="col-span-2">Kaina</div>
                        <div class="col-span-2">Viso</div>
                        <div class="col-span-1"></div>
                    </div>
                    <div
                        :key="item"
                        @mouseenter="showControls = item.id"
                        @mouseleave="showControls = null"
                        v-for="(item, key) of cartContent"
                        class="bg-white grid grid-cols-12 border-yellow border text-black relative shadow-sm hover:shadow-md transition rounded p-4 mb-4 text-extrasm cursor-pointer"
                    >
                        <div class="col-span-6">
                            {{ item.title }}
                            <!-- <span v-if="item.discount"
                >(-{{ getDiscountSize(item.discount) }}%)</span
              > -->
                        </div>
                        <div class="col-span-2">{{ item.qty }}</div>
                        <div class="col-span-2">{{ item.price }} {{ currency }}</div>
                        <div class="col-span-2">{{ (item.price * item.qty).toFixed(2) }} {{ currency }}</div>
                        <div
                            v-show="showControls === item.id"
                            @click="remove(item, key)"
                            v-tooltip="'Pašalinti prekę'"
                            v-html="trashIcon"
                            class="col-span-1 text-center absolute right-4 top-4"
                        ></div>
                    </div>
                    <div class="relative">
                        <div
                            class="border text-extrasm rounded-lg border-yellow  bg-lightYellow leading-none inline-block p-4 pt-2 mb-4 mt-0 items-center justify-center"
                        >
                            <div v-if="appliedDiscount" class="text-sm">Nuolaida: {{ appliedDiscount }}%</div>
                            <div class="font-normal text-extrasm mb-2">
                                {{ (totalWithDiscount / 1.21).toFixed(2) }} {{ currency }} + PVM
                                {{ (totalWithDiscount - totalWithDiscount / 1.21).toFixed(2) }}
                                {{ currency }}
                                <div v-if="shippingPrice">Siuntimo mokestis: {{ shippingPrice }} {{ currency }}</div>
                            </div>
                            <div class="font-bold">
                                Viso:
                                {{ totalWithShipping }} {{ currency }}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div @click="$emit('close')" v-html="closeIcon" class="absolute right-6 top-6 cursor-pointer"></div>
            <div
                id="bottom-row"
                class="relative lg:absolute bg-white lg:pt-4 mb-6 bottom-0 left-0 w-full flex items-center justify-center lg:justify-between pl-8 pr-8"
            >
                <div id="banks" class="hidden lg:flex">
                    <div
                        :key="logo"
                        :class="{
                            'ring-2 ring-yellow': selectedPayseraBank === logo.code,
                        }"
                        @click="selectedPayseraBank = logo.code"
                        v-for="logo of bankoLogos"
                        style="height:60px; width: 112px"
                        class="transition cursor-pointer border-lightblue flex items-center mr-1 mt-2 text-center justify-center"
                    >
                        <img
                            style="max-height:40px;max-width:100px"
                            class="p-2"
                            :src="`/assets/banks/${logo.title}.jpg`"
                            alt="logo"
                        />
                    </div>
                </div>
                <div v-if="cartItemsCount" class="text-center lg:text-right">
                    <div
                        id="total"
                        class="inline-block w-full lg:w-auto text-sm font-bold text-center md:mr-8 mb-2 lg:mb-0"
                    >
                        {{ totalWithShipping }} {{ currency }}
                    </div>
                    <mainBtn
                        @on-click="validateForm"
                        text="Apmokėti"
                        icon="arrow"
                        iconPos="right"
                        textColor="green"
                        bgColor="yellow"
                        :disabled="diff < 1 || countdDownSec > 0"
                        bold
                    />
                    <div>
                        <div v-if="countdDownSec > 0" class="mt-4 mx-auto text-red text-extrasm w-full mb-6">
                            Už {{ countdDownSec }} sek. būsite peradresuojami į Paysera mokėjimų portalą. <br />
                            Neuždarykite lango kol nebūsite grąžinti atgal
                        </div>
                    </div>
                    <div class="mt-4 text-extrasm" v-if="diff > 1">
                        Iki krepšelio galiojimo pabaigos liko <b> {{ remaining }} </b>
                    </div>
                    <div v-else class="text-red mt-4 text-extrasm">
                        Krepšelis nebegalioja. Susisiekite su konsultantu
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import Vue from "vue";
import VTooltip from "v-tooltip";
import inputCheckBox from "./formComponents/inputCheckBox";
import createPassword from "./smallComponents/passwords";
import { ValidationObserver } from "vee-validate";
import userContactDetails from "./userContactDetails";
import mainBtn from "./smallComponents/mainBtn";
import shippingInfo from "./shippingInfo";
import VueNotification from "@mathieustan/vue-notification";
import { db } from "./videoMessenger/fire.js";
import { mapState } from "vuex";
import { formatDistanceToNow, differenceInSeconds, addMinutes } from "date-fns";
import { lt } from "date-fns/locale";
import "cleave.js/dist/addons/cleave-phone.lt.js";
const axios = require("axios");

Vue.use(VueNotification, {
    theme: {
        colors: {
            success: "#014C40",
            error: "#C33C22",
            info: "#5d6a89",
        },
    },
    breakpoints: {
        0: {
            bottom: true,
        },
        480: {
            bottom: true,
        },
    },
});
Vue.use(VTooltip);

export default {
    props: {
        notpopup: {
            type: Boolean,
            default: false,
        },
    },
    components: {
        mainBtn,
        // toolTip,
        ValidationObserver,
        createPassword,
        userContactDetails,
        shippingInfo,
        inputCheckBox,
    },
    data() {
        return {
            remaining: null,
            loading: false,
            zityPrice: 0,
            confirmPromo: false,
            zitycityPrice: null,
            diff: -1,
            interval: null,
            selectedPayseraBank: null,
            removedItems: null,
            qtyChangedItems: null,
            shippingMethod: { code: "pats" },
            countdDownSec: 0,
            productToRemoveID: null,
            cartFinishedTime: null,
            operatorWhoFinished: null,
            privacyConfirm: false,
            createAccount: false,
            showControls: null,
            showScrollShadow: null,
            scrollContainer: null,
            passwordField: {
                id: "password",
                name: "password",
                label: "Slaptažodis",
                value: "",
                required: true,
                type: "password",
            },
            passwordConfirmation: {
                id: "confirm",
                name: "confirm",
                label: "Pakartokite slaptažodį",
                value: "",
                required: true,
                type: "password",
            },
            trashIcon:
                '<svg width="14" height="14" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-trash"><polyline points="3 6 5 6 21 6"></polyline><path d="M19 6v14a2 2 0 0 1-2 2H7a2 2 0 0 1-2-2V6m3 0V4a2 2 0 0 1 2-2h4a2 2 0 0 1 2 2v2"></path></svg>',
            bankoLogos: [
                {
                    title: "paysera",
                    code: null,
                },
                {
                    title: "swedbank",
                    code: "hanza",
                },
                {
                    title: "seb",
                    code: "vb2",
                },
                {
                    title: "luminor",
                    code: "nord",
                },
                {
                    title: "siauliu",
                    code: "sb",
                },
            ],
            currency: "€",
            closeIcon:
                '<svg width="26" height="26" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg"> <path d="M20.3125 5.6875L5.6875 20.3125" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/> <path d="M20.3125 20.3125L5.6875 5.6875" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/> </svg>',
        };
    },
    watch: {
        cartItemsCount(val) {
            if (!val) {
                this.$router.push("/");
                this.$root.notify("Prekių krepšelis tuščias");
            }
        },
        user(val) {
            if (val) {
                this.fillUserFields();
            }
        },
    },
    computed: {
        getDisabledStatus() {
            return true;
        },
        ...mapState({
            user: (state) => state.user,
            testMode: (state) => state.testMode,
            userID: (state) => state.userID,
            discounts: (state) => state.discounts,
            userInfo: (state) => state.userInfo,
            shippingPrice: (state) => state.shippingPrice,
            appliedDiscount: (state) => state.appliedDiscount,
            cartContent: (state) => state.cartContent,
            cartItemsCount: (state) => state.cartItemsCount,
        }),
        total() {
            let price = 0;
            for (let itemKey in this.cartContent) {
                price += this.cartContent[itemKey].price * this.cartContent[itemKey].qty;
            }
            return price;
        },
        totalWithDiscount() {
            return (this.total - (this.total * this.appliedDiscount) / 100).toFixed(2);
        },
        totalWithoutVATBeforeDiscount() {
            return this.total;
        },
        totalWithoutVAT() {
            let withoutVat = this.total - this.vat;
            return withoutVat;
        },
        totalWithShipping() {
            return (
                parseFloat(this.totalWithDiscount) + (this.shippingPrice ? parseFloat(this.shippingPrice) : 0)
            ).toFixed(2);
        },
        vat() {
            return (this.total * 0.21).toFixed(2);
        },
        cartIsEmpty() {
            if (!this.cartContent) return true;
            return false;
        },
    },
    mounted() {
        setTimeout(() => {
            if (!this.cartItemsCount) {
                this.$router.push("/");
                this.$root.notify("Prekių krepšelis tuščias");
            }
        }, 1000);
        if (this.user) {
            this.fillUserFields();
        }
        this.initRemoved();
        this.interval = setInterval(() => {
            this.getTimeRemaining();
            this.checkTimeTillCartRemove();
        }, 1000);
    },
    methods: {
        initRemoved() {
            const self = this;
            db.ref(`userCart/${this.userID}/removed`).on("value", function(snapshot) {
                self.removedItems = snapshot.val();
            });
            db.ref(`userCart/${this.userID}/qty_change`).on("value", function(snapshot) {
                self.qtyChangedItems = snapshot.val();
            });
        },
        fillUserFields() {
            this.userInfo.name.value = this.user.name;
            this.userInfo.email.value = this.user.email;
            this.userInfo.phone.value = this.user.phone;
            this.userInfo.city.value = this.user.city;
            this.userInfo.address.value = this.user.address;
            this.userInfo.zip.value = this.user.zip;
        },
        openLoginScreen() {
            this.$store.commit("toggleShowLoginModal", true);
        },
        getTimeRemaining() {
            if (!this.cartFinishedTime) return;
            this.diff = differenceInSeconds(addMinutes(new Date(parseInt(this.cartFinishedTime)), 45), new Date());
            this.remaining = formatDistanceToNow(addMinutes(new Date(parseInt(this.cartFinishedTime)), 45), {
                includeSeconds: true,
                locale: lt,
            });
        },
        checkTimeTillCartRemove() {
            const self = this;
            db.ref(`userCart/${this.userID}/finished`).once("value", function(snapshot) {
                if (snapshot.val() && snapshot.val().time) {
                    self.cartFinishedTime = snapshot.val().time;
                    self.operatorWhoFinished = snapshot.val().operator;
                }
            });
        },
        getDiscountSize(discount) {
            const dicountObj = this.discounts.find((element) => element.code == discount);
            if (dicountObj) {
                return dicountObj.percentage;
            } else {
                return null;
            }
        },
        priceWithoutVAT(item) {
            // const discountSize = this.getDiscountSize(item.discount);
            const priceBeforeVat = item.price / 1.21;
            // const priceAfterDiscount =
            //   priceBeforeVat - (priceBeforeVat * discountSize) / 100;
            return priceBeforeVat.toFixed(2);
        },
        remove(item, key) {
            this.$notify.closeAll();
            this.$notify.error({
                group: "foo",
                message: `Ar tikrai norite pašalinti prekę "${item.title}"?`,
                showClose: true,
                multiLine: false,
                closeDelay: 5000,
                hideIcon: false,
                offset: 0,
                fullWidth: false,
                actionText: "TAIP",
                onActionClick: () => this.finalRemove(key, item),
            });
        },
        removeAllCart() {
            this.$notify.closeAll();
            this.$notify.error({
                group: "foo",
                message: `Ar tikrai norite pašalinti visą krepšelį? Veiksmas neatstatomas`,
                showClose: true,
                multiLine: false,
                closeDelay: 8000,
                hideIcon: false,
                offset: 0,
                fullWidth: false,
                actionText: "PAŠALINTI",
                onActionClick: () => this.yesRemoveCart(),
            });
        },
        yesRemoveCart() {
            this.$notify.closeAll();
            db.ref(`userCart/${this.userID}/items/`).remove();
            if (this.cartIsEmpty) this.$emit("close");
            setTimeout(() => {
                this.$root.notify("Pirkinių krepšelis pašalintas");
            }, 500);
        },
        finalRemove(key, item) {
            this.$notify.closeAll();
            db.ref(`userCart/${this.userID}/removed`).push({
                title: item.title,
                qty: item.qty,
            });
            db.ref(`userCart/${this.userID}/items/${key}`).remove();
            if (this.cartIsEmpty) {
                db.ref(`userCart/${this.userID}`).remove();
                this.$emit("close");
            }
            setTimeout(() => {
                this.$root.notify("Pirkinys buvo pašalintas");
            }, 500);
        },
        validateForm() {
            if (this.shippingMethod.code != "pats") {
                const zip = this.userInfo.zip.value.replace(/\D/g, "");
                if (zip.length != 5) {
                    this.$root.notify("Neteisingas pašto ideksas", "error");
                    return;
                }
            }
            if (!this.privacyConfirm) {
                this.$root.notify("Pažymėkite, kad sutinkate su taisyklėmis", "info");
                return;
            }
            if (this.shippingMethod.code == "zity") {
                this.getZityPrice().then((result) => {
                    this.$store.commit("setShippingPrice", result);
                    this.zityPrice = result;
                    if (!result) return;
                    setTimeout(() => {
                        this.gotoPay();
                    }, 1000);
                });
            } else {
                this.gotoPay();
            }
        },

        gotoPay() {
            this.scrollTop();
            this.$refs.form.validate().then((success) => {
                if (!success) {
                    this.$root.notify("Užpildykite visus laukus", "info");
                    return false;
                } else {
                    this.loading = true;
                    this.payPaysera();
                }
            });
        },

        async getZityPrice() {
            const result = await axios.post(`${this.$root.baseUrl}/zitycity/getPrice/${this.testMode ? 1 : ""}`, {
                request: {
                    service_type: "express",
                    route: [
                        {
                            address: "21 Šeimyniškių LT-09236 Vilnius",
                        },
                        {
                            address: `${this.userInfo.address.value} ${this.userInfo.zip.value} ${this.userInfo.city.value}`,
                        },
                    ],
                },
            });
            if (result.data.fee) {
                return parseFloat(result.data.fee);
            }
            this.$root.notify("Skubus pristatymas šiuo adresu nėra galimas", "error");
        },

        scrollTop() {
            const element = document.querySelector("#scroll");
            setTimeout(() => {
                element.scrollTop = 0;
            }, 10);
        },

        saveAccountInfo() {
            this.$root.axiosInstance.post(`/save-user/`, this.userInfo);
        },

        async payPaysera() {
            if (this.user) {
                this.saveAccountInfo();
            }
            const response = await axios.post(`${this.$root.baseUrl}/finalizePurchase`, {
                testMode: this.testMode,
                userID: this.userID,
                createAccount: this.createAccount,
                operatorWhoFinished: this.operatorWhoFinished,
                password: this.passwordField.value,
                password_confirmation: this.passwordConfirmation.value,
                totalPrice: this.totalWithShipping,
                price: this.totalWithoutVATBeforeDiscount,
                removedItems: this.removedItems,
                qtyChangedItems: this.qtyChangedItems,
                VAT: this.vat,
                appliedDiscount: this.appliedDiscount,
                userInfo: this.userInfo,
                email: this.userInfo.email.value,
                shipping: this.shippingMethod,
                shippingPrice: this.shippingPrice,
                cart: JSON.stringify(this.cartContent),
            });
            if (response.data.error) {
                this.$root.notify(response.data.msg, "error");
                this.loading = false;
            }
            this.purchaseID = response.data.purchase_id;
            const result = await axios.post(`${this.$root.baseUrl}/payPaysera/`, {
                testMode: this.testMode,
                transaction_id: this.purchaseID,
                email: this.userInfo.email.value,
                selectedPayseraBank: this.selectedPayseraBank,
                amount: this.totalWithShipping * 100,
            });
            this.countdDownSec = 5;
            setInterval(() => {
                this.countdDownSec -= 1;
            }, 1000);
            setTimeout(() => {
                console.log(result);
                window.location.href = result.data.redirect_url;
            }, 5000);
        },

        refreshPrice(val) {
            this.$store.commit("setShippingPrice", val);
        },

        selectShippingMethod(val) {
            this.shippingMethod = val;
            this.$store.commit("setShippingPrice", val.price);
        },
    },
};
</script>

<style lang="scss" scoped>
.shadow-top {
    // box-shadow: inset 0px 7px 3px -3px rgba(50, 50, 50, 0.1);
    transition: 1s all ease;
}
@media only screen and (max-width: 600px) {
    #call-window {
        height: 88% !important;
        overflow: scroll;
    }
    #scroll,
    #scroll-left {
        height: fit-content;
        overflow: auto;
        position: relative;
    }
}
</style>
