<template>
  <ValidationObserver>
    <ValidationProvider
      rules="min:8|required|password:@confirm"
      v-slot="{ errors }"
    >
      <div class="mt-2 transition select-none" style="max-width:300px">
        <label class="w-full text-extrasm select-none"
          >Sukurkite slaptažodį
          <span class="text-red">*</span>
        </label>

        <input
          type="password"
          v-model="passwordField.value"
          class="p-2 pl-3 text-black outline-none focus:ring-1 transition focus:ring-yellow border-yellow bg-lightYellow border rounded-lg w-full"
        />
        <span class="text-extrasm text-red">{{ errors[0] }}</span>
      </div>
    </ValidationProvider>

    <ValidationProvider name="confirm" rules="required" v-slot="{ errors }">
      <div class="mt-2 transition select-none" style="max-width:300px">
        <label class="w-full text-extrasm select-none"
          >Pakartokite
          <span class="text-red">*</span>
        </label>

        <input
          type="password"
          v-model="passwordConfirmation.value"
          class="p-2 pl-3 text-black outline-none focus:ring-1 transition focus:ring-yellow border-yellow bg-lightYellow border rounded-lg w-full"
        />
        <span class="text-extrasm text-red">{{ errors[0] }}</span>
      </div>
    </ValidationProvider>
  </ValidationObserver>
</template>

<script>
import { ValidationProvider, extend } from "vee-validate";
import { min } from "vee-validate/dist/rules";

extend("password", {
  params: ["target"],
  validate(value, { target }) {
    return value === target;
  },
  message: "Slaptažodžiai nesutampa",
});

extend("min", {
  ...min,
  message: "Slaptažodį turi sudaryti min. 8 simboliai",
});

export default {
  props: {
    passwordField: {
      type: Object,
    },
    passwordConfirmation: {
      type: Object,
    },
  },
  components: {
    ValidationProvider,
  },
};
</script>

<style></style>
