<template>
  <div @click="$emit('on-click')" class="flex items-center mt-4 text-extrasm">
    <div
      class="p-3 transition relative cursor-pointer bg-lightYellow bg-ligh border rounded border-yellow"
    >
      <span
        class="absolute top-0 left-0 w-full h-full flex items-center justify-center"
        v-show="checked"
        v-html="check"
      ></span>
    </div>
    <div
      v-if="method == 'privacy'"
      style="max-width:300px"
      class="ml-2 cursor-pointer select-none"
    >
      Patvirtinu, kad susipažinau ir sutinku su
      <span class="font-bold cursor-pointer" @click="open('return')"
        >pristatymo ir grąžinimo tvarka
      </span>
      ir
      <span class="font-bold cursor-pointer" @click="open('privacy')"
        >privatumo politika
      </span>
    </div>
    <div
      v-else
      style="max-width:300px"
      class="ml-2 cursor-pointer select-none"
      v-html="text"
    ></div>
  </div>
</template>

<script>
export default {
  props: {
    text: {
      type: String,
    },
    method: {},
    checked: {
      type: String,
    },
  },
  data() {
    return {
      check:
        '<svg style="fill:none;stroke:#333;stroke-width:2;stroke-linecap:round;stroke-linejoin:round;" x="0px" y="0px" width="14px" height="14px" viewBox="0 0 18 13" style="overflow:visible;enable-background:new 0 0 18 13;" xml:space="preserve"><polyline class="st0" points="17,1 6,12 1,7 "/> </svg> ',
    };
  },
  methods: {
    open(page) {
      this.$store.commit("triggerShowPrivacyOrReturns", page);
    },
  },
};
</script>

<style></style>
