<template>
  <div class="container mx-auto">
    <purchaseScreen notpopup />
  </div>
</template>

<script>
import purchaseScreen from "./components/purchaseScreen";

export default {
  components: { purchaseScreen },
};
</script>

<style></style>
