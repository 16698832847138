<template>
  <div class="mt-4 p-0 text-sm">
    <div
      @click="selectShipping(item)"
      :key="item.code"
      v-for="item of methods"
      class="flex cursor-pointer items-center mt-2"
    >
      <div style="height:26px" class="border-yellow border rounded-full">
        <div
          :class="{ 'bg-yellow': shipping.code == item.code }"
          class="m-2 p-1 rounded-full transition"
        ></div>
      </div>
      <div class="ml-2 text-lightblue font-light text-extrasm">
        {{ item.label }} -
        <span v-if="item.code == 'pats'">0 €</span>
        <span v-else>{{ item.price || "?" }} €</span>
      </div>
    </div>
  </div>
</template>

<script>
const axios = require("axios");
import { mapState } from "vuex";

export default {
  props: {
    userInfo: {
      type: Object,
    },
    zityPrice: {
      type: Number,
    },
  },
  data() {
    return {
      zitycityPrice: null,
      shipping: {},
    };
  },
  mounted() {
    this.shipping = this.methods[0];
  },
  watch: {
    zityPrice(val) {
      this.zitycityPrice = val;
    },
  },
  computed: {
    ...mapState({
      testMode: (state) => state.testMode,
    }),
    methods() {
      return [
        {
          label: "Atsiimsiu pats parduotuvėje (Šeimyniškių gatvė 21, Vilnius)",
          code: "pats",
          price: null,
        },
        {
          label: "DPD standartinis (iki 2 d.d. visoje Lietuvoje)",
          code: "dpd",
          price: 5,
        },
        {
          label: "Pristatymas per 2 val su ZitiCity (Vilniuje)",
          code: "zity",
          price: this.zitycityPrice,
        },
      ];
    },
  },
  methods: {
    async getQuote() {
      if (!this.userInfo.city.value || !this.userInfo.address.value) {
        this.$root.notify(
          "Užpildykite pristatymo informacijos laukus",
          "error"
        );
        return false;
      }

      const result = await axios.post(
        `${this.$root.baseUrl}/zitycity/getPrice/${this.testMode ? 1 : ""}`,
        {
          request: {
            service_type: "express",
            route: [
              {
                address: "21 Šeimyniškių LT-09236 Vilnius",
              },
              {
                address: `${this.userInfo.address.value} ${this.userInfo.zip.value} ${this.userInfo.city.value}`,
              },
            ],
          },
        }
      );
      if (result.data.fee) {
        this.zitycityPrice = parseFloat(result.data.fee);
        this.$emit("price-updated", parseFloat(result.data.fee));
        return;
      }
      this.$root.notify("Skubus pristatymas šiuo adresu nėra galimas", "error");
    },
    selectShipping(shipping) {
      if (shipping.code == "zity") {
        this.getQuote();
      }
      this.$emit("select-shipping", shipping);
      this.shipping = shipping;
    },
  },
};
</script>

<style></style>
